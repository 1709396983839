import './ParticipantItem.scss';
import React, {ChangeEvent, FC, ReactNode, useState} from 'react';
import Avatar from "../../atoms/Avatar/Avatar";
import Tag from "../../atoms/Tag/Tag";
import DeleteIcon from "../../atoms/icons/DeleteIcon";


interface IParticipantItem{
    isCheck?:boolean;
    id?:number;
    userName?: string;
    name: string;
    counter?: number;
    ip?:ReactNode;
    clue?:boolean
    status?:number | null;
    date?: string;
    type?: ReactNode;
    onSelect?: (checked:boolean)=>void;
    isSelected?:boolean;
    isDelete?:boolean;
    onDelete?: ()=>void
}

const ParticipantItem:FC<IParticipantItem> = ({
                                                  id,
                                                  userName='',
                                                  type,
                                                  isSelected=false,
                                                  isCheck=true,
                                                  onSelect= ()=>{},
                                                  name,
                                                  status,
                                                  ip,
                                                  date,
                                                  isDelete=false,
                                                  onDelete=()=>{}
                                              }) => {

    return (
        <div className={`participant-item`} >
            <div className="participant-item__wrapper">
{/*                {isCheck && <div className="participant-item__select">
                    <Checkbox checked={isSelected} onChange={onSelect}/>
                </div>}*/}
                <div className={"participant-item__inner"} >
                    <div className="participant-item__id">ID:{id}</div>
                    <div className="participant-item__avatar"><Avatar name={userName ? userName : name} type={!!name} /></div>
                    <div className="participant-item__user-name">{userName}</div>
                    <div className="participant-item__name">{name}</div>
                    <div className="participant-item__ip">{ip}</div>
                    {type && <div className="participant-item__type">{type}</div>}
                    <div className="participant-item__date"><Tag name={`${date}`} color={"#104100"}/></div>
                    <div className="participant-item__controls">
                        {isDelete &&   <a onClick={onDelete}><DeleteIcon/></a>}
                    </div>
                </div>

            </div>

        </div>
    );
};

export default ParticipantItem;