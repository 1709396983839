import {createAsyncThunk} from "@reduxjs/toolkit";

import { IParticipant} from "../../../helpers/types";
import ParticipantsService from "../../../api/ParticipantsService";


export const asyncGetSentMailsAction = createAsyncThunk(
    'participants/allMailSent',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit,search,categoryId} = query;
            let response = await ParticipantsService.findAllSentMails(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncSendPersonalMailAction = createAsyncThunk(
    'settings/sendPersonalMail',
    async (settings:any, thunkAPI)=>{
        try {
            const {id,emails,theme,message} = settings;
            let response = await ParticipantsService.sendPersonalMail(settings);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncGetParticipantsAction = createAsyncThunk(
    'participants/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit,search,categoryId,onlyEmail,onlyPhone,country} = query;


            let response = await ParticipantsService.findAllParticipants(sort,limit,page,onlyEmail,onlyPhone,country);
            return response.data;

        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncGetParticipantsCodesAction = createAsyncThunk(
    'participants/findAllCodes',
    async (_, thunkAPI)=>{
        try {
            let response = await ParticipantsService.findAllCodes();
            return response.data;

        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);




export const asyncUpdateParticipantAction = createAsyncThunk(
    'participants/update',
    async (participant:IParticipant, thunkAPI)=>{
        try {
            const {id,email,status} = participant;
            const formData = new FormData();
            formData.append('email', email as string);
            formData.append('status', status as unknown as string );

            let response = await ParticipantsService.update(formData, id as number);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteParticipantAction = createAsyncThunk(
    'participants/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await ParticipantsService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);