import './Preloader.scss';
import {FC} from 'react';

interface IPreloader {
    type?: "relative" | "absolute" | "fixed"
}
const Preloader:FC<IPreloader> = ({type="fixed"}) => {
    return (
        <div className={`preloader`}>
            <div className="preloader__background"></div>
            <div className="preloader__wrapper">
                <div className="preloader__img"></div>

            </div>

        </div>
    );
};

export default Preloader;