import {FC, useCallback, useEffect, useState} from 'react';
import ParticipantsContainer from "../../components/containers/ParticipantsContainer";
import RectButton from "../../components/atoms/RectButton/RectButton";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import instance from "../../api/api";
import {useParams} from "react-router-dom";
import {
    asyncGetParticipantsAction,
    asyncGetParticipantsCodesAction
} from "../../redux/slices/participants/participantsAction";
import {setCurrentCode, setCurrentPageParticipants} from "../../redux/slices/participants/participantsSlice";

const OrdersPage:FC = () => {

    const dispatch = useDispatchEx();
    const { codes,currentCode } = useSelectorEx(state => state.participants)

    const handleExport = async () => {
        try {
            const currentCategory = category ? Number(category) : null;
            const response = await instance.get('/participants/export', {
                params:{
                    onlyEmail: currentCategory === 1 ? true : null,
                    onlyPhone: currentCategory === 2 ? true : null,
                    country: currentCode
                },
                responseType: 'blob', // Указываем тип ответа
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentType = response.headers['content-type'];
            if (!contentType || !contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                throw new Error('Received incorrect content type');
            }

            const blob = new Blob([response.data], { type: contentType });

            const url = window.URL.createObjectURL(blob);

            const link:any = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'emails.xlsx');
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
        }
    };
    const { category } = useParams();

    useEffect(() => {
        dispatch(asyncGetParticipantsCodesAction())
    }, []);


    const changeCountry = (code:string|null) =>{
        const currentCategory = category ? Number(category) : null;
        dispatch(asyncGetParticipantsAction({
            page: 1,
            onlyEmail: currentCategory === 1 ? true : null,
            onlyPhone: currentCategory === 2 ? true : null,
            country: code
        }));
        dispatch(setCurrentCode(code))
    }

//href={`${process.env.REACT_APP_URL_SERVER}/api/participants/export`}
    return (
        <div className={'main-admin__content'}>
            <div className="main-admin__header">
            <h1 style={{color:"#ffffff",marginBottom:"20px"}}>List emails</h1>
            </div>
            <div className="main-admin__filters">
                <div className={'main-admin__buttons'}>
                    <RectButton type={'htmlLink'} onClick={handleExport}  className={'btn-rect-gradient-long'} name={'Export XLSX'} />
                </div>
                <div className="main-admin__filters-block">
                    <div className="main-admin__filters-wrapper">
                        <h2 style={{color:"#ffffff",marginBottom:"20px"}}>Filters:</h2>
                        <div className="main-admin__filters-list">
                            <RectButton href={'/admin/participants/'} className={` ${!category ? "active" : ""}`} name={'All'} />
                            <RectButton href={'/admin/participants/category/1'} className={`${category === "1" ? "active" : ""}`} name={'Email'} />
                            <RectButton href={'/admin/participants/category/2'}   className={` ${category === "2" ? "active" : ""}`} name={'Phone'} />
                        </div>

                    </div>
                    <div className="main-admin__filters-wrapper">
                        <h2 style={{color:"#ffffff",marginBottom:"20px"}}>Countries:</h2>
                        <div className="main-admin__filters-list">
                            {codes.length ?
                                // href={`/admin/participants/${category ? "category/"+category : ""}`}
                                <>
                                    <RectButton onClick={()=>changeCountry(null)} className={` ${!currentCode ? "active" : ""}`} name={'All'} />
                                    {codes.map(code => {
                                        if(!code) return ;
                                            return <RectButton key={code} onClick={()=>changeCountry(code)} className={`${currentCode === "1" ? "active" : ""}`} name={code ? code : "Unset"} />
                                       })}
                                </>
                                : ""}

                        </div>
                    </div>
                </div>


            </div>
            <ParticipantsContainer/>
        </div>
    );
};

export default OrdersPage;