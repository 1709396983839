import './ParticipantsList.scss';

import {FC} from 'react';
import {IParticipant} from "../../../helpers/types";
import ParticipantItem from "../ParticipantItem/ParticipantItem";
import Placeholder from "../../atoms/Placeholder/Placeholder";
import Pagination from "../../atoms/Pagination/Pagination";
import {getTimeDate} from "../../../helpers/functions";

interface IParticipantsList{
    isCheck?: boolean;
    items: IParticipant[];
    onChangePagination?:(value:number)=>void;
    totalCount?:number;
    currentPage?:number;
    perPage?:number;
    pageUrl?:string;
    onCheckSelect?: (name:string,checked:boolean)=>void
    onDelete?: (id:number)=>void
    isDelete?: boolean
}

const ParticipantsList:FC<IParticipantsList> = ({isDelete=false,onDelete=()=>{},isCheck=true,onCheckSelect=()=>{},items,onChangePagination=()=>{},totalCount=0,perPage=12,currentPage=1,pageUrl=''}) => {
    return (
        <div className={'participant-list'}>
            {items.length > 0 ?
                items.map(item=> {
                    return <div key={item.id} className="participant-list__item"><ParticipantItem
                        isDelete={isDelete}
                        onDelete={()=>onDelete(item.id)}
                        isCheck={isCheck}
                        isSelected={item?.isSelected}
                        id={item.id}
                        onSelect={(e)=>onCheckSelect(item.email as string,e)}
                        userName={item.phone}
                        name={item.email as string}
                        status={item.status ? Number(item.status) : null}
                        type={item.type ? item.type: null}
                        ip={item.ip}
                        date={getTimeDate(item.createdAt)}
                    /></div>
                })
                : <Placeholder header={'Ups!'} message={'Users not found'} />
            }
            {totalCount > 0 &&
                <div className="participant-list__pagination">
                    <Pagination pageUrl={pageUrl} currentPage={currentPage} totalItems={totalCount} itemsPerPage={perPage} onPageChange={onChangePagination}/>
                </div>
            }
        </div>
    );
};

export default ParticipantsList;